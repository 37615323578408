body {
  font-size: 15px;
  color: #000000;
  font-family: "Poppins", sans-serif;
}
a,
a:hover {
  color: #FFFFFF;
}
:root {
  --primary: #F36A2B;
  --bg: hsl(330, 6%, 7%);
  --form-bg: #F5F5F5;
  --primary_bg: #F36A2B;
  --white_color: #FFFFFF;
}
.primary_color {
  background-color: var(--primary);
}
.forgot_password {
  color: var(--primary);
}
.form_bg {
  background-color: var(--form-bg);
}
.full_bg {
  background: url(./assets/banner_bg.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 80vh;
}
.login_heading {
  color: var(--primary);
  font-size: 34px;
}
.header-link {
  font-size: 14px;
}
.form-control,
button.btn,
.form-select,
.btn {
  font-size: 14px;
  border-radius: 25px;
}
.btn .btn-primary {
  color: #fff;
  border-color: #F36A2B;
  background-color: #F36A2B;
  border-color: #F36A2B;
  border-top-color: #F36A2B;
  border-right-color: #F36A2B;
  border-bottom-color: #F36A2B;
  border-left-color: #F36A2B;
}
.body_height {
  min-height: 90vh;
}
footer {
  background-color: var(--bg);
  min-height: 10vh;
}
footer.p-3 a {
  font-size: 11px;
  text-decoration: none;
  color: #F6F9FC;
}
/*after login*/
.header_bg {
  background-color: var(--bg);
}
.welcome_heading {
  font-size: 24px;
  color: var(--primary);
}
.text-center.forgot_password.fs-5.p-2 {
  border-radius: 10px;
  border: solid 1px #ccc;
}
.mb-5.choose-number {
  border: solid 1px #ccc;
  padding: 20px;
  border-radius: 15px;
}
.text-center.forgot_password.fs-5.p-2 {
  max-width: 80%;
  text-align: center;
  margin: -45px auto auto;
}
.text-center.forgot_password.fs-5.p-2 {
  border-radius: 10px;
  border: solid 1px #ccc;
  margin-top: -45px;
  display: block;
  background-color: #fff;
}
.selected {
  border: solid 1px var(--primary);
}
.circle {
  border: solid 3px var(--primary);
  display: inline-block;
  border-radius: 50%;
  width: 40px;
  padding: 5px 13px;
}
.time_style a {
  background-color: #F5F5F5;
  margin-bottom: 25px;
  color: #333;
  border-radius: 25px;
  padding: 5px 15px;
  font-weight: 500;
  float: left;
  margin-right: 12px;
  text-decoration: none;
}
.time_style p {
  color: #333;
  text-align: start;
  font-size: 14px;
  font-weight: 500;
}
a.selectedbg {
  background-color: var(--primary_bg);
  color: var(--white_color);
  font-weight: bold;
}
.inspection_cost p.time::after,
p.cost::after {
  content: "";
  border-bottom: solid 1px #ccc;
  width: 100%;
  display: inline-block;
}
.cost span,
p.total.mb-1 span {
  width: 75%;
  display: inline-block;
}
p.total.mb-1 span {
  width: 80%;
  display: inline-block;
}
.gst {
  text-align: end;
  width: 95%;
  font-size: 12px;
}
button.fs-6.mb-3 {
  display: inline-block;
  background-color: #000;
  width: auto;
  border-radius: 3px;
  margin-left: 15px;
  color: rgb(255, 255, 255);
  font-family: "Poppins", sans-serif;
}
.inspection_cost p {
  color: #000000;
}
.inspection_cost {
  border: solid 1px #CCCCCC;
  border-radius: 10px;
  background-color: #F6F9FC;
  padding: 10px;
}
@media screen and (min-width: 768px) {
  .time_style.col-md-4.mb-3 {
    margin-top: 20px;
  }
  .btn.btn-primary {
    background-color: #F36A2B;
    font-size: 14px;
    border-radius: 25px;
    display: inline-block;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.475rem 0.95rem;
    color: white;
    margin-left: 4em;
  }
}
.btn.btn-primary:hover {
  background-color: #F36A2B;
}
.btn.btn.btn-primary:active {
  background-color: #F36A2B;
}
.text-decoration-none {
  color: #ccc;
}
.text-decoration-none:hover {
  color: #ccc;
}
.carcheckbox {
  background-color: #F36A2B;
  width: 1000px;
}
.form::selection {
  border: solid 1px #F36A2B;
}
.primary_color {
  background-color: var(--primary);
}
.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
.check {
  margin: 1px;
  background-color: #F36A2B;
  border-radius: 25px;
  border: 1px solid #fff;
  overflow: hidden;
  float: left;
  margin: 8px 10px;
  margin-left: 0;
  background-color: #F0EFEF;
  border-color: #E6E6E6;
  color: #000000 !important;
  border-radius: 25px !important;
  font-family: "Poppins", sans-serif;
}
.check:active {
  background-color: #F36A2B;
}
.check label {
  float: left;
  line-height: 2em;
  width: 5.5em;
  height: 2em;
}
.check label span {
  text-align: center;
  display: block;
}
.check label input {
  position: absolute;
  display: none;
  color: rgb(0, 0, 0) !important;
}
.check label input + span {
  color: rgb(0, 0, 0);
}
.check input:checked + span {
  color: #FAFAFA;
  background-color: #F36A2B;
  font-weight: bold;
}
.checkbox::selection {
  color: #CCCCCC;
}
form.int {
  border-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}
.form-selected {
  border-radius: 25px;
  width: 93%;
}
.check:not(:first-child)::before {
  display: none;
}
.check:hover {
  position: relative;
  color: #F36B3D !important;
}
.check:checked {
  color: #fff !important;
  background: #F36B3D !important;
  border-color: #F36B3D !important;
}
.form-select.check:checked {
  background-color: #F36B3D;
  border-color: #F36B3D;
}
.selected {
  border: solid 1px #F36B3D;
}
.disabled-link {
  pointer-events: none;
  color: white;
  background-color: white;
}
.is_disabled {
  cursor: default;
  text-decoration: none;
}
.is_active {
  background-color: #F36A2B;
  font-size: 14px;
  border-radius: 25px;
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.475rem 0.95rem;
  color: white;
}
.time-slot {
  display: inline-block;
  margin-right: 12px;
}
.btn-custom {
  background-color: #F36A2B;
  font-size: 14px;
  border-radius: 25px;
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  color: white;
  border: 1px solid transparent;
  padding: 0.475rem 0.95rem;
  margin-left: 4em;
}
.btn-custom:hover {
  border: 1px solid black;
}
.sr-input {
  width: 28em;
  height: 2em;
  margin: 10px;
  border: none;
  background-color: #fff;
}
.sr-input::after, ::before {
   box-sizing: none;
}
.card {
  width: 30rem;
  height: 14rem;
  margin-top: 0;
  background-color: rgb(248, 248, 248);
  border: none;
}
.sr-card-element{
  background-color: #FFFFFF;
  padding: 5px;
  margin: 10px;
}
.btn-btn{
  background-color: #F36A2B;
    font-size: 16px;
    border-radius: 5px;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.3rem ;
    color: white;
    margin: 5px;
}
.form-msg{
  color:red;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  padding:10px
}
.date-msg{
  color:red;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  padding:10px
}
.sr-field-success.message{
  justify-content: center;
  text-align: center;
  margin: 14.5em;
}
.text-span{
  color: #000000;
  text-align: start;
  font-size: 13px;
}

.isChecked{
  background-color: #F36B3D;
  color: rgb(255, 255, 255) !important;
}