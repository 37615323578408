.PageOutLine {
	width: 100%;
	float: left;
	padding-top: 70px;
	background-color:white !important;
}
.TermsConditionsContainer {
	width: 100%;
	float: left;
	background-color: #fff;
}
.TermsConditionsTitle {
    width: 100%;
    float: left;
    padding: 10px 0;
    padding-top: 30px;
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid #d3d3d3;
    color: #1f2c45;
}
.TermsConditionsContainer p, .TermsConditionsContainer li {
    margin-bottom: 15px;
    color: #676767;
    font-size: 13px;
    font-weight: 300;
	text-align: justify;
}
.TermsConditionsContainer p strong, .TermsConditionsContainer li strong {
    color: #000;
}