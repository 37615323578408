.sr-combo-inputs {
    margin: 20px 0;
  }
  
  .sr-input {
    font-size: 16px;
    width: 92%;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #cccccc;
  }
  
  .sr-card-element {
    padding-top: 12px;
    /* height: 44px; */
  }
  
  .btn {
    font-size: 16px;
  }

  .card-responsive {
    width: 100%;
  }

  .card-image{
    width: 95%;
    margin-left: 15px;
  }

  .card-header{
    color: grey;
    background-color: #f8f8f8;
    border: none;
  }

  .card-header-wrapper{
    text-align: left;
  }